import { Icon, Fill, Stroke, Text, Style, Circle } from "ol/style";
// import { toStringXY } from 'ol/coordinate';
// import MultiPoint from 'ol/geom/MultiPoint';

// // 河流边框
// var water_stroke = new Stroke({
//     color: "rgba(97, 191, 242, 1.0)",
//     width: 1
// });

// //河流填充色
// var water_fill = new Fill({
//     color: "rgba(97, 191, 242, 1.0)",
// });

// var water_style = new Style({
//     stroke: new Stroke({
//         color: "rgba(96, 191, 243, 1.0)",
//         width: 3,
//     }),
//     text: new Text({
//         font: "12px Calibri,sans-serif",
//         fill: new Fill({
//             color: "#000",
//         }),
//         stroke: new Stroke({
//             color: "#fff",
//             width: 3,
//         }),
//     }),
// })

var vector_style = [
    new Style({
        fill: new Fill({
            color: "rgba(0, 0, 0, 0.5)",
        }),
    }),
    new Style({
        stroke: new Stroke({
            color: "rgba(0, 0, 0, 1)",
            width: 6,
        }),
    })
    // stroke: new Stroke({
    //     color: "rgba(0, 0, 255, 1.0)",
    //     width: 6,
    // }),
];

var city_style = function (feature) {
    return new Style({
        image: new Icon({ src: require("../../assets/icon/map_icon_lbs.png") }),
        text: new Text({
            //位置
            textAlign: 'left',
            //文字样式
            font: 'normal 12px 微软雅黑',
            //文本内容
            text: feature.get('name'),
            offsetX: 9,
            //文本填充样式（即文字颜色）
            fill: new Fill({ color: '#000000' }),
            // stroke: Stroke({ color: '#ffcc33', width: 12 })
        })
    });
};

var road_gs_label_style = function (feature) {
    return new Style({
        image: new Icon({ src: require("../../assets/icon/gs.png") }),
        text: new Text({
            //位置
            // textAlign: 'left',
            //文字样式
            font: 'normal 9px 微软雅黑',
            //文本内容
            text: feature.get('LXBM'),
            // offsetX: 9,
            offsetY: 3,
            //文本填充样式（即文字颜色）
            fill: new Fill({ color: '#fff' }),
            // stroke: Stroke({ color: '#ffcc33', width: 12 })
        })
    });
};

var road_g_label_style = function (feature) {
    return new Style({
        image: new Icon({ src: require("../../assets/icon/g.png") }),
        text: new Text({
            //位置
            // textAlign: 'left',
            //文字样式
            font: 'normal 9px 微软雅黑',
            //文本内容
            text: feature.get('LXBM'),
            // offsetX: 9,
            offsetY: 2,
            //文本填充样式（即文字颜色）
            fill: new Fill({ color: '#fff' }),
            // stroke: Stroke({ color: '#ffcc33', width: 12 })
        })
    });
};

var road_s_label_style = function (feature) {
    return new Style({
        image: new Icon({ src: require("../../assets/icon/s.png") }),
        text: new Text({
            //位置
            // textAlign: 'left',
            //文字样式
            font: 'normal 9px 微软雅黑',
            //文本内容
            text: feature.get('LXBM'),
            // offsetX: 9,
            offsetY: 2,
            //文本填充样式（即文字颜色）
            fill: new Fill({ color: '#fff' }),
            // stroke: Stroke({ color: '#ffcc33', width: 12 })
        })
    });
};

var meatureStyle = new Style({
    fill: new Fill({
        color: "rgba(125, 125, 125, 0.2)",
    }),
    stroke: new Stroke({
        color: "rgba(0, 0, 0, 0.5)",
        lineDash: [10, 10],
        width: 2,
    }),
    image: new Circle({
        radius: 5,
        stroke: new Stroke({
            color: "rgba(0, 0, 0, 0.7)",
        }),
        fill: new Fill({
            color: "rgba(255, 255, 255, 0.2)",
        }),
    }),
});


// var county_style = function (feature) {
//     return new Style({
//         text: new Text({
//             //位置
//             textAlign: 'left',
//             //文字样式
//             font: 'normal 14px 微软雅黑',
//             //文本内容
//             text: feature.get('NAME'),
//             //文本填充样式（即文字颜色）
//             fill: new Fill({ color: 'rgba(163, 163, 163, 1.0)' }),
//         })
//     });
// };

// var settlement_style = function (feature) {
//     return new Style({
//         text: new Text({
//             //位置
//             textAlign: 'left',
//             //文字样式
//             font: 'normal 14px 微软雅黑',
//             //文本内容
//             text: feature.get('Xzmc'),
//             //文本填充样式（即文字颜色）
//             fill: new Fill({ color: 'rgba(163, 163, 163, 1.0)' }),
//         })
//     });
// };

// var village_style = function (feature) {
//     return new Style({
//         text: new Text({
//             //位置
//             textAlign: 'left',
//             //文字样式
//             font: 'normal 14px 微软雅黑',
//             //文本内容
//             text: feature.get('Jzcmc'),
//             //文本填充样式（即文字颜色）
//             fill: new Fill({ color: 'rgba(163, 163, 163, 1.0)' }),
//         })
//     });
// };

// var natural_style = function (feature) {
//     return new Style({
//         text: new Text({
//             //位置
//             textAlign: 'left',
//             //文字样式
//             font: 'normal 14px 微软雅黑',
//             //文本内容
//             text: feature.get('ZRCMC'),
//             //文本填充样式（即文字颜色）
//             fill: new Fill({ color: 'rgba(163, 163, 163, 1.0)' }),
//         })
//     });
// };



// var brige_style = new Style({
//     image: new Icon({
//         anchor: [0.5, 20],
//         anchorXUnits: "fraction",
//         anchorYUnits: "pixels",
//         src: require("../../assets/icon/brigde.png"),
//     }),
//     zIndex: Infinity,
// })

//计划
var plan_style = [
    new Style({
        fill: new Fill({
            color: "rgba(0, 0, 255, 0.5)",
        })
    }),
    new Style({
        stroke: new Stroke({
            color: "rgba(0, 0, 255, 1)",
            width: 2,
            lineCap: "butt",
        })
    })
];


// //高速
// var road_gs_style = [
//     new Style({
//         fill: new Fill({
//             color: "rgba(255, 172, 77, 1.0)",
//         })
//     }),
//     new Style({
//         stroke: new Stroke({
//             color: "rgba(255, 172, 77, 1.0)",
//             width: 1,
//             lineCap: "butt",
//         })
//     })
// ];

// var road_g_style = [
//     //内
//     new Style({
//         fill: new Fill({
//             color: "rgba(251, 227, 163, 1.0)",
//         })
//     }),
//     //边
//     new Style({
//         stroke: new Stroke({
//             color: "rgba(247, 215, 140, 1.0)",
//             width: 1,
//             lineCap: "butt",
//         })
//     })
// ];


var road_s_style = [
    //内
    new Style({
        fill: new Fill({
            color: "rgba(255, 255, 255, 1.0)",
        })
    }),
    //边
    new Style({
        stroke: new Stroke({
            color: "rgba(215, 215, 215, 1.0)",
            width: 1,
            lineCap: "butt",
        })
    })
];

var air_style = new Style({
    image: new Icon({
        anchor: [0.5, 46],
        anchorXUnits: "fraction",
        anchorYUnits: "pixels",
        src: require("../../assets/icon/airport-15.png"),
    }),
    zIndex: Infinity,
});

var hinge_style = function (feature) {
    console.log('feature', feature);
    // var xmlx = feature.properties_.项目类;
    var xmlx = feature.values_.项目类;
    
    // console.log('xmlx', xmlx);
    var picSrc = '';
    switch (xmlx) {
        case "客运枢纽":
            picSrc = require("../../assets/icon/passenger.png");
            break;
        case "货运枢纽":
            picSrc = require("../../assets/icon/freight.png");
            break;
        case "乡镇运输服务站":
            picSrc = require("../../assets/icon/township_transportation.png");
            break;
    }
    console.log(picSrc);
    var style = new Style({
        image: new Icon({
            anchor: [0.5, 46],
            anchorXUnits: "fraction",
            anchorYUnits: "pixels",
            src: picSrc,
        }),
    });
    return style;
}

// var tunnel_style = new Style({
//     image: new Icon({
//         anchor: [0.5, 20],
//         anchorXUnits: "fraction",
//         anchorYUnits: "pixels",
//         src: require("../../assets/icon/tunnel.png"),
//     }),
//     zIndex: Infinity,
// });

// var ferry_style = new Style({
//     image: new Icon({
//         anchor: [0.5, 20],
//         anchorXUnits: "fraction",
//         anchorYUnits: "pixels",
//         src: require("../../assets/icon/ferry.png"),
//     }),
//     zIndex: Infinity,
// });


// var railway_style = [
//     new Style({
//         stroke: new Stroke({
//             color: "rgba(165, 165, 178, 1.0)",
//             // color: "rgba(190, 196, 205, 1.0)",
//             width: 3,
//             lineCap: "butt",
//         }),
//         zIndex: 20,
//     }),
//     new Style({
//         stroke: new Stroke({
//             color: "#ffffff",
//             width: 3,
//             lineDash: [15, 15],
//             lineCap: "butt",
//         }),
//         zIndex: 21,
//     }),
// ];

// var province_style = new Style({
//     stroke: new Stroke({
//         color: "#E04747",
//         width: 3,
//     }),
// });

// /**
//  * 面状水系样式
//  *  @feature 
//  *  @resolution 
//  * */
// function polygonStyleFunction(feature, resolution) {
//     return new Style({
//         stroke: water_stroke,
//         fill: water_fill,
//         text: createTextStyle(feature, resolution),
//     });
// }

// var facial_rivers_style = new Style({
//     stroke: water_stroke,
//     fill: water_fill,
// });

// // 创建文本样式
// const createTextStyle = function (feature, resolution) {
//     return new Text({
//         textAlign: "center",
//         // textBaseline: "alphabetic",
//         font: "Arial",
//         text: getText(feature, resolution),
//         fill: new Fill({ color: "rgba(34, 77, 168, 1.0)" }),
//         stroke: new Stroke({ color: "#ffffff", width: 3 }),
//         offsetX: 0,
//         offsetY: 0,
//         placement: "point",
//         // maxAngle: 0.7853981633974483,
//         overflow: true,
//         rotation: 0,
//     });
// };

// // 格式化文本
// const getText = function (feature, resolution) {
//     let text = feature.get("NAME");
//     if (resolution < 5000) {
//         text = stringDivider(text, 16, "\n");
//     }
//     else {
//         text = '';
//     }
//     return text;
// };

// function stringDivider(str, width, spaceReplacer) {
//     if (str.length > width) {
//         let p = width;
//         while (p > 0 && str[p] != " " && str[p] != "-") {
//             p--;
//         }
//         if (p > 0) {
//             let left;
//             if (str.substring(p, p + 1) == "-") {
//                 left = str.substring(0, p + 1);
//             } else {
//                 left = str.substring(0, p);
//             }
//             const right = str.substring(p + 1);
//             return (
//                 left + spaceReplacer + stringDivider(right, width, spaceReplacer)
//             );
//         }
//     }
//     return str;
// }


export {
    // polygonStyleFunction,
    // settlement_style,
    // village_style,
    road_gs_label_style,
    road_g_label_style,
    road_s_label_style,
    // natural_style,
    city_style,
    meatureStyle,
    // county_style,
    // tunnel_style,
    plan_style,
    air_style,
    // facial_rivers_style,
    // province_style,
    // water_style,
    vector_style,
    // brige_style,
    // railway_style,
    // road_gs_style,
    // road_g_style,
    road_s_style,
    hinge_style,
    // ferry_style
};