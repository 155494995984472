var baseUrl = "http://121.89.201.182:8088/geoserver/";
var restUrl = "http://39.100.134.93:6080/";

//背景
var World_Imagery_url = "https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer"; //卫星遥感
// var ChinaOnlineCommunity_url = "http://map.geoq.cn/arcgis/rest/services/ChinaOnlineCommunity/MapServer";//背景图
var wmsUrl = baseUrl + "cite/wms?"; //wms
// var provinceUrl = baseUrl + "gwc/service/tms/1.0.0/cite:province@EPSG:900913@pbf/{z}/{x}/{-y}.pbf";//省界
// var majorRiversUrl = baseUrl + "gwc/service/tms/1.0.0/cite:major_rivers@EPSG:900913@pbf/{z}/{x}/{-y}.pbf";// 主要河流
// var facialRiversUrl = baseUrl + "gwc/service/tms/1.0.0/cite:facial_rivers@EPSG:900913@pbf/{z}/{x}/{-y}.pbf";// 面状水系
// var lake_Url = baseUrl + "gwc/service/tms/1.0.0/cite:lake@EPSG:900913@pbf/{z}/{x}/{-y}.pbf";// 湖泊
// var line_water_Url = baseUrl + "gwc/service/tms/1.0.0/cite:line_water@EPSG:900913@pbf/{z}/{x}/{-y}.pbf";// 单线河流
var city_url = baseUrl + "gwc/service/tms/1.0.0/cite:city@EPSG:900913@pbf/{z}/{x}/{-y}.pbf"; // 地级市驻地
// var county_url = baseUrl + "gwc/service/tms/1.0.0/cite:county@EPSG:900913@pbf/{z}/{x}/{-y}.pbf";// 县级驻地
// var district_url = baseUrl + "gwc/service/tms/1.0.0/cite:district@EPSG:900913@pbf/{z}/{x}/{-y}.pbf";// 市辖区

var amap_url = "http://webst01.is.autonavi.com/appmaptile?lang=zh_cn&size=1&style=7&x={x}&y={y}&z={z}"; //高德切片

// 公路底图
// var road_gs_Url = baseUrl + "gwc/service/tms/1.0.0/cite:Geo_Road_GS_Polygon_Thinn@EPSG:900913@pbf/{z}/{x}/{-y}.pbf";// 高速
// var road_g_Url = baseUrl + "gwc/service/tms/1.0.0/cite:Geo_Road_G_Polygon_Thinn@EPSG:900913@pbf/{z}/{x}/{-y}.pbf";// 国道
// var road_s_Url = baseUrl + "gwc/service/tms/1.0.0/cite:Geo_Road_S_Polygon_Thinn@EPSG:900913@pbf/{z}/{x}/{-y}.pbf";// 省道
// var road_x_Url = baseUrl + "gwc/service/tms/1.0.0/cite:Geo_Road_X_Polygon_Thinn@EPSG:900913@pbf/{z}/{x}/{-y}.pbf";// 县道
// var road_y_Url = baseUrl + "gwc/service/tms/1.0.0/cite:Geo_Road_Y_Polygon_Thinn@EPSG:900913@pbf/{z}/{x}/{-y}.pbf";// 乡道
// var road_z_Url = baseUrl + "gwc/service/tms/1.0.0/cite:Geo_Road_Z_Polygon_Thinn@EPSG:900913@pbf/{z}/{x}/{-y}.pbf";// 专道
// var road_c_Url = baseUrl + "gwc/service/tms/1.0.0/cite:Geo_Road_C_Polygon_Thinn@EPSG:900913@pbf/{z}/{x}/{-y}.pbf";// 村道
// var bridgeUrl = baseUrl + "gwc/service/tms/1.0.0/cite:Geo_Bridge@EPSG:900913@pbf/{z}/{x}/{-y}.pbf";// 桥梁
// var culvertUrl = baseUrl + "gwc/service/tms/1.0.0/cite:Geo_Culvert@EPSG:900913@pbf/{z}/{x}/{-y}.pbf";// 涵洞
// var tunnelUrl = baseUrl + "gwc/service/tms/1.0.0/cite:Geo_Tunnel@EPSG:900913@pbf/{z}/{x}/{-y}.pbf";// 隧道
// var ferryUrl = baseUrl + "gwc/service/tms/1.0.0/cite:Geo_Ferry@EPSG:900913@pbf/{z}/{x}/{-y}.pbf"; // 渡口
// var settlement_url = baseUrl + "gwc/service/tms/1.0.0/cite:settlement@EPSG:900913@pbf/{z}/{x}/{-y}.pbf";// 乡镇
// var village_url = baseUrl + "gwc/service/tms/1.0.0/	cite:formed_village@EPSG:900913@pbf/{z}/{x}/{-y}.pbf";// 建制村
// var natural_url = baseUrl + "gwc/service/tms/1.0.0/cite:Geo_NaturalVillage@EPSG:900913@pbf/{z}/{x}/{-y}.pbf";// 自然村

// 标注
var road_gs_point_url = baseUrl + "gwc/service/tms/1.0.0/cite:Geo_Road_GS_Point@EPSG:900913@pbf/{z}/{x}/{-y}.pbf"; // 高速一级标注
var road_gs_point_second_url =
    baseUrl + "gwc/service/tms/1.0.0/cite:Geo_Road_GS_Point_Second@EPSG:900913@pbf/{z}/{x}/{-y}.pbf"; // 高速二级标注

var road_g_point_url = baseUrl + "gwc/service/tms/1.0.0/cite:Geo_Road_G_Point@EPSG:900913@pbf/{z}/{x}/{-y}.pbf"; //国道标注
var road_s_point_url = baseUrl + "gwc/service/tms/1.0.0/cite:Geo_Road_S_Point@EPSG:900913@pbf/{z}/{x}/{-y}.pbf"; //省道标注

// 十四五规划相关
var planUrl = baseUrl + "gwc/service/tms/1.0.0/cite:plan_road@EPSG:900913@pbf/{z}/{x}/{-y}.pbf"; // 公路
var airportUrl = baseUrl + "gwc/service/tms/1.0.0/cite%3Aairport@EPSG%3A900913@pbf/{z}/{x}/{-y}.pbf"; // 机场
// var railwaysUrl = baseUrl + "gwc/service/tms/1.0.0/cite:railways@EPSG:900913@pbf/{z}/{x}/{-y}.pbf";// 铁路
var hingeUrl = baseUrl + "gwc/service/tms/1.0.0/cite%3Aplan_hinge@EPSG%3A900913@pbf/{z}/{x}/{-y}.pbf"; // 枢纽
var backgroundUrl = baseUrl + "gwc/service/tms/1.0.0/cite:background@EPSG:900913@pbf/{z}/{x}/{-y}.pbf";


export {
    baseUrl,
    restUrl,
    wmsUrl,
    World_Imagery_url,
    // ChinaOnlineCommunity_url,
    amap_url,
    // provinceUrl,
    city_url,
    // county_url,
    // road_gs_Url,
    // road_g_Url,
    // road_s_Url,
    // road_x_Url,
    // road_y_Url,
    // road_z_Url,
    // road_c_Url,
    // bridgeUrl,
    // culvertUrl,
    // tunnelUrl,
    // ferryUrl,
    planUrl,
    airportUrl,
    hingeUrl,
    // railwaysUrl,
    // majorRiversUrl,
    // facialRiversUrl,
    // district_url,
    // settlement_url,
    // village_url,
    // natural_url,
    // lake_Url,
    // line_water_Url,
    road_gs_point_url,
    road_gs_point_second_url,
    road_g_point_url,
    road_s_point_url,
    backgroundUrl,
};
