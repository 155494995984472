// import OSM from "ol/source/OSM";
import { TileArcGISRest } from "ol/source";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import VectorTileLayer from "ol/layer/VectorTile";
import VectorTileSource from "ol/source/VectorTile";
import MVT from "ol/format/MVT";

import WMTS from 'ol/source/WMTS';
import Projection from 'ol/proj/Projection';
import WMTSTileGrid from 'ol/tilegrid/WMTS';

// import { createXYZ } from 'ol/tilegrid';
// import TileGrid from 'ol/tilegrid/TileGrid';
// import { get } from 'ol/proj';
import TileWMS from 'ol/source/TileWMS';

import {
    baseUrl,
    restUrl,
    wmsUrl,
    World_Imagery_url,
    // ChinaOnlineCommunity_url,
    amap_url,
    // provinceUrl,
    city_url,
    // county_url,
    // road_gs_Url,
    // road_g_Url,
    // road_s_Url,
    // road_x_Url,
    // road_y_Url,
    // road_z_Url,
    // road_c_Url,
    // bridgeUrl,
    // culvertUrl,
    // tunnelUrl,
    // ferryUrl,
    // backgroundUrl,
    // planUrl,
    // airportUrl,
    // railwaysUrl,
    // majorRiversUrl,
    // facialRiversUrl,
    // district_url,
    // settlement_url,
    // village_url,
    // natural_url,
    // lake_Url,
    // line_water_Url,
    road_gs_point_url,
    road_gs_point_second_url,
    road_g_point_url,
    road_s_point_url,
    // hingeUrl,
} from "../map/url";

import {
    // polygonStyleFunction,
    // settlement_style,
    // village_style,
    road_gs_label_style,
    road_g_label_style,
    road_s_label_style,
    // natural_style,
    city_style,
    // hinge_style,
    // county_style,
    // tunnel_style,
    // plan_style,
    // air_style,
    // facial_rivers_style,
    // province_style,
    // water_style,
    // vector_style,
    // brige_style,
    // railway_style,
    // road_gs_style,
    // road_g_style,
    // road_s_style,
    // ferry_style
} from "../map/style";

//高德切片
let gdLayer = new TileLayer({
    source: new XYZ({
        url: amap_url,
    }),
    // opacity: 0.7,
});
console.log(gdLayer);

/************************** 动态 *************************/
// //自然村
// let naturalLayer = new TileLayer({
//     source: new TileWMS({
//         url: wmsUrl,
//         params: {
//             'FORMAT': "image/png",
//             'VERSION': '1.1.1',
//             tiled: true,
//             STYLES: '',
//             LAYERS: 'cite:Geo_NaturalVillage',
//         }
//     })
// });
// naturalLayer.setMaxResolution(10);


// let backgroundLayer = new TileLayer({
//     source: new TileWMS({
//         url: wmsUrl,
//         params: {
//             'FORMAT': "image/png",
//             'VERSION': '1.1.1',
//             tiled: true,
//             STYLES: '',
//             LAYERS: 'cite:background',
//         }
//     })
// });

let airportLayer = new TileLayer({
    source: new TileWMS({
        url: wmsUrl,
        params: {
            'FORMAT': "image/png",
            'VERSION': '1.1.1',
            tiled: true,
            STYLES: '',
            LAYERS: 'cite:plan_airport',
            "exceptions": 'application/vnd.ogc.se_inimage',
        }
    }),
    visible: false
});


let planLayer = new TileLayer({
    source: new TileWMS({
        url: wmsUrl,
        params: {
            'FORMAT': "image/png",
            'VERSION': '1.1.1',
            tiled: true,
            STYLES: '',
            LAYERS: 'cite:plan_road',
            "exceptions": 'application/vnd.ogc.se_inimage',
        }
    }),
    visible: false
});


//arcgis rest服务中获取公路地图
let roadLayer = new TileLayer({
    // extent: [-13884991, 2870341, -7455066, 6338219],
    source: new TileArcGISRest({
        url: restUrl + "arcgis/rest/services/Gis2013_LD/MapServer",
    }),
});

/************************** 切片 *************************/

//ESRI影像
let bgLayer = new TileLayer({
    // extent: [
    //   11219356.938023157, 4560970.729695495, 15832065.529795818,
    //   7190364.878100108,
    // ],
    source: new TileArcGISRest({
        url: World_Imagery_url,
        // url: ChinaOnlineCommunity_url,
    }),
    visible: false,
});


var resolutions = [156543.03390625, 78271.516953125, 39135.7584765625, 19567.87923828125, 9783.939619140625, 4891.9698095703125, 2445.9849047851562, 1222.9924523925781, 611.4962261962891, 305.74811309814453, 152.87405654907226, 76.43702827453613, 38.218514137268066, 19.109257068634033, 9.554628534317017, 4.777314267158508, 2.388657133579254, 1.194328566789627, 0.5971642833948135, 0.29858214169740677, 0.14929107084870338, 0.07464553542435169, 0.037322767712175846, 0.018661383856087923, 0.009330691928043961, 0.004665345964021981, 0.0023326729820109904, 0.0011663364910054952, 5.831682455027476E-4, 2.915841227513738E-4, 1.457920613756869E-4];

//切片名
var matrixIds = ['EPSG:900913:0', 'EPSG:900913:1', 'EPSG:900913:2', 'EPSG:900913:3', 'EPSG:900913:4',
    'EPSG:900913:5', 'EPSG:900913:6', 'EPSG:900913:7', 'EPSG:900913:8', 'EPSG:900913:9', 'EPSG:900913:10',
    'EPSG:900913:11', 'EPSG:900913:12', 'EPSG:900913:13', 'EPSG:900913:14', 'EPSG:900913:15', 'EPSG:900913:16',
    'EPSG:900913:17', 'EPSG:900913:18', 'EPSG:900913:19', 'EPSG:900913:20', 'EPSG:900913:21', 'EPSG:900913:22',
    'EPSG:900913:23', 'EPSG:900913:24', 'EPSG:900913:25', 'EPSG:900913:26', 'EPSG:900913:27', 'EPSG:900913:28',
    'EPSG:900913:29', 'EPSG:900913:30'
];

//设置地图投影
var projection = new Projection({
    code: 'EPSG:900913',
    units: 'm',
    axisOrientation: 'neu'
});
//背景wmts
var backgroundLayer = new TileLayer({
    source: new WMTS({
        url: baseUrl + 'gwc/service/wmts',
        layer: 'cite:backdrop',
        // layer: 'cite:background',
        matrixSet: 'EPSG:900913',
        format: 'image/png',
        projection: projection,
        tileGrid: new WMTSTileGrid({
            tileSize: [256, 256],
            extent: [-2.003750834E7, -2.003750834E7, 2.003750834E7, 2.003750834E7],
            origin: [-2.003750834E7, 2.003750834E7],
            resolutions: resolutions,
            matrixIds: matrixIds
        }),
        style: '',
        wrapX: true,
    }),
});

/************************** 矢量切片 *************************/

// //背景
// var backgroundLayer = new VectorTileLayer({
//     source: new VectorTileSource({
//         format: new MVT(),
//         url: backgroundUrl,
//     }),
//     style: plan_style,
// });


// //计划公路
// let planLayer = new VectorTileLayer({
//     source: new VectorTileSource({
//         format: new MVT(),
//         url: planUrl,
//         //cacheSize: 1
//     }),
//     style: plan_style,
// });

// //机场
// let airportLayer = new VectorTileLayer({
//     source: new VectorTileSource({
//         format: new MVT(),
//         url: airportUrl,
//         //cacheSize: 1
//     }),
//     style: air_style,
//     // visible: false,
// });
// airportLayer.setMaxResolution(900);

// //枢纽
// let hingeLayer = new VectorTileLayer({
//     source: new VectorTileSource({
//         format: new MVT(),
//         url: hingeUrl,
//         //cacheSize: 1
//     }),
//     style: hinge_style,
//     visible: false,
// });

//地级市驻地
let cityLayer = new VectorTileLayer({
    source: new VectorTileSource({
        format: new MVT(),
        url: city_url,
        //cacheSize: 1
    }),
    style: city_style,
});
cityLayer.setMinResolution(100);

//高速标签
let roadGsLabelLayer = new VectorTileLayer({
    source: new VectorTileSource({
        format: new MVT(),
        url: road_gs_point_url,
        //cacheSize: 1
    }),
    style: road_gs_label_style,
});
roadGsLabelLayer.setMaxResolution(1350);
roadGsLabelLayer.setMinResolution(120);

//高速二级标签
let roadGsSecondLabelLayer = new VectorTileLayer({
    source: new VectorTileSource({
        format: new MVT(),
        url: road_gs_point_second_url,
        //cacheSize: 1
    }),
    style: road_gs_label_style,
});
roadGsSecondLabelLayer.setMaxResolution(120);

//国道标签
let roadGLabelLayer = new VectorTileLayer({
    source: new VectorTileSource({
        format: new MVT(),
        url: road_g_point_url,
        //cacheSize: 1
    }),
    style: road_g_label_style,
});
roadGLabelLayer.setMaxResolution(60);
// roadGLabelLayer.setMinResolution(120);

//省道标签
let roadSLabelLayer = new VectorTileLayer({
    source: new VectorTileSource({
        format: new MVT(),
        url: road_s_point_url,
        //cacheSize: 1
    }),
    style: road_s_label_style,
});
roadSLabelLayer.setMaxResolution(60);
// roadSLabelLayer.setMinResolution(120);


// //湖泊
// let lakeLayer = new VectorTileLayer({
//     source: new VectorTileSource({
//         format: new MVT(),
//         url: lake_Url,
//         //cacheSize: 1
//     }),
//     style: facial_rivers_style,
// });
// lakeLayer.setMaxResolution(1500);


// //面状水系
// var facialRiversLayer = new VectorTileLayer({
//     source: new VectorTileSource({
//         format: new MVT(),
//         url: facialRiversUrl,
//     }),
//     style: facial_rivers_style,
// });


// //省界
// var provinceLayer = new VectorTileLayer({
//     source: new VectorTileSource({
//         format: new MVT(),
//         url: provinceUrl,
//         //cacheSize: 1
//     }),
//     style: province_style,
// });
// console.log(province_style);
// console.log(provinceUrl);
// provinceLayer.setMinResolution(600);

// //主要河流
// let majorRiversLayer = new VectorTileLayer({
//     source: new VectorTileSource({
//         format: new MVT(),
//         url: majorRiversUrl,
//         //cacheSize: 1
//     }),
//     style: water_style,
// });
// majorRiversLayer.setMaxResolution(1000);

// //单线河
// let lineWaterLayer = new VectorTileLayer({
//     source: new VectorTileSource({
//         format: new MVT(),
//         url: line_water_Url,
//         //cacheSize: 1
//     }),
//     style: water_style,
// });

// lineWaterLayer.setMaxResolution(130);


// //高速
// let roadGSLayer = new VectorTileLayer({
//     source: new VectorTileSource({
//         format: new MVT(),
//         url: road_gs_Url,
//         //cacheSize: 1
//     }),
//     style: road_gs_style,
// });

// //国道
// let roadGLayer = new VectorTileLayer({
//     source: new VectorTileSource({
//         format: new MVT(),
//         url: road_g_Url,
//         //cacheSize: 1
//     }),
//     style: road_g_style,
// });
// console.log(roadGLayer);
// roadGLayer.setMaxResolution(600);

// //省道
// let roadSLayer = new VectorTileLayer({
//     source: new VectorTileSource({
//         format: new MVT(),
//         url: road_s_Url,
//         //cacheSize: 1
//     }),
//     style: road_g_style,
// });
// roadSLayer.setMaxResolution(400);

// //县道
// let roadXLayer = new VectorTileLayer({
//     source: new VectorTileSource({
//         format: new MVT(),
//         url: road_x_Url,
//         //cacheSize: 1
//     }),
//     style: road_s_style,
// });
// roadXLayer.setMaxResolution(30);

// //乡道
// let roadYLayer = new VectorTileLayer({
//     source: new VectorTileSource({
//         format: new MVT(),
//         url: road_y_Url,
//         //cacheSize: 1
//     }),
//     style: road_s_style,
// });
// roadYLayer.setMaxResolution(15);

// //专道
// let roadZLayer = new VectorTileLayer({
//     source: new VectorTileSource({
//         format: new MVT(),
//         url: road_z_Url,
//         //cacheSize: 1
//     }),
//     style: road_s_style,
// });
// roadZLayer.setMaxResolution(15);

// //村道
// let roadCLayer = new VectorTileLayer({
//     source: new VectorTileSource({
//         format: new MVT(),
//         url: road_c_Url,
//         //cacheSize: 1
//     }),
//     style: road_s_style,
// });
// roadCLayer.setMaxResolution(10);

// //桥梁
// let bridgeLayer = new VectorTileLayer({
//     source: new VectorTileSource({
//         format: new MVT(),
//         url: bridgeUrl,
//         //cacheSize: 1
//     }),
//     style: brige_style,
// });
// bridgeLayer.setMaxResolution(10);

// //涵洞
// let culvertLayer = new VectorTileLayer({
//     source: new VectorTileSource({
//         format: new MVT(),
//         url: culvertUrl,
//         //cacheSize: 1
//     }),
//     style: air_style,
// });

// culvertLayer.setMaxResolution(3);

// //隧道
// let tunnelLayer = new VectorTileLayer({
//     source: new VectorTileSource({
//         format: new MVT(),
//         url: tunnelUrl,
//         //cacheSize: 1
//     }),
//     style: tunnel_style,
// });
// tunnelLayer.setMaxResolution(70);

// //渡口
// let ferryLayer = new VectorTileLayer({
//     source: new VectorTileSource({
//         format: new MVT(),
//         url: ferryUrl,
//         //cacheSize: 1
//     }),
//     style: ferry_style,
// });
// ferryLayer.setMaxResolution(10);


// //铁路
// let railwaysLayer = new VectorTileLayer({
//     source: new VectorTileSource({
//         format: new MVT(),
//         url: railwaysUrl,
//         //cacheSize: 1
//     }),
//     style: railway_style,
// });

// railwaysLayer.setMaxResolution(60);


// //县级驻地
// let countyLayer = new VectorTileLayer({
//     source: new VectorTileSource({
//         format: new MVT(),
//         url: county_url,
//         //cacheSize: 1
//     }),
//     style: county_style,
// });
// countyLayer.setMaxResolution(446);

// //区级驻地
// let districtLayer = new VectorTileLayer({
//     source: new VectorTileSource({
//         format: new MVT(),
//         url: district_url,
//         //cacheSize: 1
//     }),
//     style: county_style,
// });
// districtLayer.setMaxResolution(150);
// districtLayer.setMinResolution(30);

// //乡镇
// let settlementLayer = new VectorTileLayer({
//     source: new VectorTileSource({
//         format: new MVT(),
//         url: settlement_url,
//         //cacheSize: 1
//     }),
//     style: settlement_style,
// });
// settlementLayer.setMaxResolution(100);
// settlementLayer.setMinResolution(30);

// //建制村
// let villageLayer = new VectorTileLayer({
//     source: new VectorTileSource({
//         format: new MVT(),
//         url: village_url,
//         //cacheSize: 1
//     }),
//     style: village_style,
// });
// villageLayer.setMaxResolution(30);

// //自然村
// let naturalLayer = new VectorTileLayer({
//     source: new VectorTileSource({
//         format: new MVT(),
//         url: natural_url,
//         //cacheSize: 1
//     }),
//     style: natural_style,
// });
// naturalLayer.setMaxResolution(10);

export {
    gdLayer,
    bgLayer,
    backgroundLayer,
    // facialRiversLayer,
    planLayer,
    airportLayer,
    roadLayer,
    // roadGSLayer,
    // roadGLayer,
    // roadSLayer,
    // roadXLayer,
    // roadYLayer,
    // roadZLayer,
    // roadCLayer,
    // bridgeLayer,
    // culvertLayer,
    // tunnelLayer,
    // ferryLayer,
    // railwaysLayer,
    // majorRiversLayer,
    // lineWaterLayer,
    // lakeLayer,
    cityLayer,
    roadGsLabelLayer,
    roadGsSecondLabelLayer,
    roadGLabelLayer,
    roadSLabelLayer,
    // hingeLayer,
    // countyLayer,
    // districtLayer,
    // settlementLayer,
    // villageLayer,
    // naturalLayer
}